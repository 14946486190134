<template>
  <main-layout
    v-if="isDataLoaded"
    :entity-name="name"
    :entity-logo="logo"
    :entity-list="entityList"
    :parent-entity-name="isUserSuperadmin ? $t('superadmin.name') : null"
    :parent-entity-route="isUserSuperadmin ? { name: 'superadmin' } : null"
    :sidebar-items="sidebarItems"
  >
    <router-view :key="($route.params.organizationId || '')" />
  </main-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import MainLayout from '@/components/MainLayout'

export default {
  name: 'Organization',
  components: {
    MainLayout,
  },
  props: {
    organizationId: { type: String, required: true },
  },
  data() {
    return {
      isDataLoaded: false,
      userOrganizations: null,
    }
  },
  computed: {
    ...mapGetters('user', ['adminOrganizationIds', 'collabOrganizationIds']),
    ...mapGetters('superadmin', ['isUserSuperadmin']),
    ...mapGetters('organization', ['sidebarItems']),
    name() { return this.$store.state.organization.dbData?.name },
    logo() { return this.$store.state.organization.dbData?.logo || this.$store.state.organization.dbData?.logos[0]?.publicUrl },
    entityList({ userOrganizations }) {
      return userOrganizations.filter(org => org.id !== this.organizationId).map(({ id, name, logos }) => ({
        id,
        name,
        logo: logos[0].publicUrl,
        route: { name: 'organization', params: { organizationId: id } },
      }))
    },
  },
  async created() {
    this.$store.commit('loader/show')
    if (this.$store.state.organization.dbData?.id !== this.organizationId) this.$router.go()
    const userOrganizationIds = [...this.adminOrganizationIds, ...this.collabOrganizationIds]
    this.userOrganizations = await Promise.all(userOrganizationIds.map(orgId => this.$store.dispatch('organization/read', orgId)))
    this.$store.commit('loader/hide')
    this.isDataLoaded = true
  },
}
</script>
